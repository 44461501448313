import { useField } from 'formik';
import { Key, ReactNode } from 'react';
import { ComboBoxItem } from '../../input/combo-box/ComboBox';
import {
  RSComboboxWithQuery,
  RSComboboxWithQueryProps,
  SearchableQueryVariables,
} from '../../input/combo-box/RSComboBox';
import FormErrorText from '../form-error-text/FormErrorText';
import FormLabel from '../FormLabel';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface FormComboBoxWithQueryProps<
  TEntity,
  TQuery,
  TQueryVariables extends SearchableQueryVariables<TFilterInput>,
  TFilterInput,
> extends RSComboboxWithQueryProps<TEntity, TQuery, TQueryVariables, TFilterInput> {
  required?: boolean;
  label?: string;
  initialSelection?: ComboBoxItem;
  inLineLabel?: boolean;
  key?: Key;
  icon?: ReactNode;
  onChangeHandler?: (value: ComboBoxItem<TEntity> | undefined) => void;
}

export default function FormComboBoxWithQuery<
  TEntity,
  TQuery,
  TQueryVariables extends SearchableQueryVariables<TFilterInput>,
  TFilterInput,
>(props: FormComboBoxWithQueryProps<TEntity, TQuery, TQueryVariables, TFilterInput>) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, meta, helpers] = useField({
    name: props.name,
  });

  const wrapperClassName = props.inLineLabel
    ? 'flex flex-row my-2 mx-2 flex-grow items-baseline'
    : 'flex flex-col my-1 flex-grow';

  return (
    <div className={wrapperClassName}>
      <FormLabel label={props.label} required={props.required} />
      <RSComboboxWithQuery<TEntity, TQuery, TQueryVariables, TFilterInput>
        name={props.name}
        dataAccessorCallback={props.dataAccessorCallback}
        searchFieldNames={props.searchFieldNames}
        query={props.query}
        variables={props.variables}
        initialValue={props.initialValue}
        onChange={(item) => {
          helpers.setTouched(true);
          helpers.setValue(item?.value);
          props.onChangeHandler && props.onChangeHandler(item);
        }}
        //Defulats to a "Search" placeholder and magnifying glass icon if not provided
        placeholder={props.placeholder || 'Search'}
        icon={props.icon || <FontAwesomeIcon icon={faSearch} />}
        placement={props.placement}
        first={props.first}
      />
      <FormErrorText meta={meta} />
    </div>
  );
}
